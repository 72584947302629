<script>
import DefaultMixin from '../mixin'
import {find} from "@/domain/gerencia/servidor/services"
import {UFakeLoader} from "uloc-vue"
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import GlobalPersonMixin from "@/components/pessoa/globalPersonMixin"
import GlobalBemMixin from "@/components/bem/globalBemMixin"
import AcaoModel from "@/components/gerencia/servidor/components/buttons/Acao"

export default {
  name: 'Servidor',
  provide: function () {
    return {
      container: this
    }
  },
  mixins: [DefaultMixin, GlobalPersonMixin, GlobalBemMixin],
  components: {
    AcaoModel,
    ECol,
    ERow,
    UFakeLoader
  },
  data() {
    return {
      loading: true,
      model: null,
    }
  },
  computed: {
  },
  mounted() {
    this.$route.params.id && this.load()
  },
  destroyed() {
  },
  methods: {
    load() {
      this.loading = true
      find(this.$route.params.id)
          .then(response => {
            console.log(response)
            this.loading = false
            this.model = response.data
            this.model.ips = JSON.parse(this.model.ips)
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    }
  },
  meta: {
    title: 'Model',
    name: 'Model'
  }
}
</script>

<template>
  <div class="model-container wrapper-md">
    <div class="sl-layout-page dark">
      <div v-if="model && model.status === 99"
           class="u-alert-default u-alert-content wrapper-lg m-b no-select">
        Model recusada.
      </div>
      <div v-if="model && model.status === 100"
           class="u-alert-default u-alert-content wrapper-lg m-b no-select bg-green text-white">
        Model aprovada.
      </div>
      <div class="sl-layout-page-actions">
        <a @click="$router.go(-1)" class="float-left"><i class="fa fa-arrow-left text-blue-grey-3" /> Voltar</a>
        <acao-model :model="model" @updated="load" />
      </div>
      <div v-if="loading">
        <u-fake-loader width="90%"/>
        <br>
        <u-fake-loader width="30%"/>
        <u-fake-loader width="50%"/>
        <u-fake-loader width="40%"/>
        <br><br>
        <u-fake-loader width="20%"/>
        <u-fake-loader width="40%"/>
        <br>
        <br>
        <br>
        <br>
        <br>
        <u-fake-loader width="20%"/>
      </div>
      <div v-else-if="model.id">
        <h2 class="diviser">Dados do Servidor</h2>
        <div class="m-t">
          <e-row class="erp-row-view" mr>
            <e-col style="min-width: 50px">
              <label>ID</label>
              <p>{{ model.id }}</p>
            </e-col>
            <e-col>
              <label>Registro</label>
              <p class="text-nowrap">{{ model.createdAt|formatDate }}</p>
            </e-col>
            <e-col>
              <label class="text-nowrap">Última modificação</label>
              <p class="text-nowrap">{{ model.dateModified|formatDate }}</p>
            </e-col>
            <e-col>
              <label class="text-nowrap">Situação</label>
              <p class="text-nowrap"><span class="text-positive" v-if="model.active">Ativo</span><span v-else class="text-negative">Inativo</span> </p>
            </e-col>
          </e-row>
          <e-row class="erp-row-view" mr>
            <e-col>
              <label>Nome</label>
              <p class="text-nowrap">{{ model.nome }}</p>
            </e-col>
            <e-col>
              <label>Endpoint</label>
              <p class="text-nowrap"><a :href="model.url" target="_blank">{{ model.url }}</a></p>
            </e-col>
            <e-col>
              <label>IPs:</label>
              <p class="text-nowrap">{{ Array.isArray(model.ips) ? model.ips.join(', ') : model.ips }}</p>
            </e-col>
            <e-col>
              <label>Token</label>
              <p class="text-nowrap">{{ model.token }}</p>
            </e-col>
          </e-row>
          <e-row class="erp-row-view" mr>
            <e-col class="full-width col-grow">
              <label>Descrição</label>
              <p>{{ model.descricao }}</p>
            </e-col>
          </e-row>
          <e-row class="erp-row-view" mr>
            <e-col class="full-width col-grow">
              <label>Configurações</label>
              <p>{{ model.config }}</p>
            </e-col>
          </e-row>
        </div>

      </div>
    </div>
  </div>
</template>
