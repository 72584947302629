<script>
import {UPopover} from 'uloc-vue'
import MenuOptionsItem from '@/components/layout/context-menu/context-window-options-item'
import {deleteServidor} from "@/domain/gerencia/servidor/services"
import servidorWindow from "@/components/gerencia/servidor/windows/servidor"

export default {
  name: 'AcaoServidor',
  inject: ['container'],
  props: ['model'],
  components: {MenuOptionsItem, UPopover},
  computed: {
  },
  data() {
    return {
    }
  },
  methods: {
    editar () {
      this.abrir(this.model.id)
    },
    servidorWindow,
    novo() {
      this.servidorWindow()
    },
    abrir(id) {
      this.servidorWindow(id)
    },
    entityUpdate () {
      this.$emit('updated')
    },
    load () {
      this.container && this.container.load()
    },
    print () {
      window.print()
    },
    excluir() {
      this.$uloc.dialog({
        title: 'Excluir registro',
        message: 'Você tem certeza que deseja excluir este registro?',
        ok: 'Sim',
        cancel: 'Não',
        color: 'negative'
      }).then(() => {
        this.$uloc.loading.show({message: 'Deletando...'})
        deleteServidor(this.model.id)
            .then(() => {
              this.$uloc.loading.hide()
              this.$router.push({name: 'gerencia.servidor'})
            })
            .catch(error => {
              this.alertApiError(error)
            })
      }).catch(() => {
      })
    }
  }
}
</script>

<template>
  <e-btn align="left" label="Ações">
    <span class="m-l-md"></span> <u-icon name="chevron-down" type="fa" class="absolute-right m-l m-r-sm text-grey-7" style="font-size: 10px !important;"/>
    <u-popover class="window-context-menu no-border-radius dark" anchor="bottom left" self="top left">
      <div class="menu_ menu-btn-leilao">
        <ul>
          <menu-options-item @click="editar" label="Editar" close  />
        </ul>
        <ul>
          <menu-options-item @click="print" label="Imprimir" close  />
        </ul>
        <ul>
          <menu-options-item @click="excluir" label-class="text-negative" label="Excluir Servidor" close  /> <!-- @TODO -->
        </ul>
        <slot />
      </div>
    </u-popover>
  </e-btn>
</template>
