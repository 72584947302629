import http from '../../../../utils/services/http'

export const list = (limit, page, filtros = '') => {
  let url = '/api/servidores?page=' + page + '&limit=' + limit + filtros
  return http.get(url)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}


export const find = (id) => {
  let url = `/api/servidores/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const newServidor = (data) => {
  let url = `/api/servidores`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateServidor = (id, data) => {
  let url = `/api/servidores/${id}`
  return http.put(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const deleteServidor = (id) => {
  let url = `/api/servidores/${id}`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}
