export default function (id) {
  let window = id ? `servidor.${id}` : 'servidor.new'
  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: 'Servidor' + (id ? ` #${id}` : ''),
    width: '1000',
    height: '800',
    minHeight: '500px',
    backdrop: false,
    clickOutside: false,
    maximize: true,
    windowClass: 'erp-window erp-window-macos-code',
    contentClass: 'overflow-hidden',
    props: {
      id: id
    }
  }, () => import('../components/window/Servidor.vue'))
    .then((wid) => {
      this.$uloc.window.listen(wid, {
        update: (wid, val) => {
          this.$emit('update')
          this.load && this.load()
        }
      })
    }) // return wid
}
